import { EventEmitter } from 'o365-modules';

type QueryEvents = {
    'QueryChanged': (queryName: string, queryResult: boolean) => void
};

type QueryDefinitions = {
    [key: string]: string | null
};

type DefaultQueryDefinitions = typeof defaultQueryDefinitions;

const defaultQueryDefinitions = {
    'isMobile': 'screen and (pointer: coarse) and (orientation: portrait) and (max-width: 600px), screen and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)',
    'isTablet': 'screen and (pointer: coarse) and (orientation: portrait) and (min-width: 600px) and (max-width: 1024px), screen and (pointer: coarse) and (orientation: landscape) and (min-height: 600px) and (max-height: 1024px)',
    'isMobileOrTablet': 'screen and (pointer: coarse) and (orientation: portrait) and (max-width: 1024px), screen and (pointer: coarse) and (orientation: landscape) and (max-height: 1024px)',
    'isDesktop': 'screen and (any-pointer: fine)',
    'isLandscape': 'screen and (orientation: landscape)',
    'isPortrait': 'screen and (orientation: portrait)',
    'isTouch': 'screen and (pointer: coarse) and (not (any-pointer: fine))',
    'canHover': 'screen and (hover: hover)',
    'xs': '(max-width: 575.98px)',
    'sm': '(min-width: 576px) and (max-width: 767.98px)',
    'md': '(min-width: 768px) and (max-width: 991.98px)',
    'lg': '(min-width: 992px) and (max-width: 1199.98px)',
    'xl': '(min-width: 1200px) and (max-width: 1399.98px)',
    'xxl': '(min-width: 1400px)'
} as const;

class MediaQueryManager {
    private queries: QueryDefinitions = {};
    private eventHandler: EventEmitter<QueryEvents>;
    private matchers: Map<string, MediaQueryList> = new Map();

    constructor() {
        this.eventHandler = new EventEmitter();
        this.initializeDefaultQueries();
    }

    private initializeDefaultQueries() {
        for (const queryName in defaultQueryDefinitions) {
            const query = defaultQueryDefinitions[queryName];
            const matcher = window.matchMedia(query);
            const onChangeHandler = this.onChange.bind(this, queryName);

            matcher.addEventListener('change', onChangeHandler);

            this.matchers.set(queryName, matcher);
        }
    }

    private onChange(queryName: string, event: MediaQueryListEvent) {
        this.eventHandler.emit('QueryChanged', queryName, event.matches);
    }

    public on<K extends keyof QueryEvents>(event: K, listener: QueryEvents[K]) {
        return this.eventHandler.on(event, listener);
    }

    public once<K extends keyof QueryEvents>(event: K, listener: QueryEvents[K]) {
        return this.eventHandler.once(event, listener);
    }

    public off<K extends keyof QueryEvents>(event: K, listener: QueryEvents[K]) {
        return this.eventHandler.off(event, listener);
    }

    public getQueryMatchState(queryName: string): boolean | undefined {
        return this.matchers.get(queryName)?.matches;
    }

    // Return all the matching query names
    public getMatchingQueries(): Array<string | null> {
        return Array.from(this.matchers, ([key, value]) => {
            return value.matches ? key : null;
        }).filter(item => item !== null);
    }

    public registerCustomQuery(queryName: string, query: string): boolean | undefined {
        if (defaultQueryDefinitions.hasOwnProperty(queryName)) {
            console.error(`Cannot create custom query '${queryName}' because it is reserved.`);
            return;
        }

        if (this.queries.hasOwnProperty(queryName)) {
            if (this.queries[queryName] === query) {
                return this.matchers.get(queryName)?.matches;
            }

            console.warn(`Custom query '${queryName}' has been overwritten.`);
        }

        const matcher = window.matchMedia(query);
        const onChangeHandler = this.onChange.bind(this, queryName);

        matcher.addEventListener('change', onChangeHandler);

        this.matchers.set(queryName, matcher);

        return matcher.matches;
    }

    public registerCustomQueries<T extends QueryDefinitions>(queries: T): { [K in keyof T | keyof DefaultQueryDefinitions]: boolean | undefined } {
        let result: { [K in keyof T | keyof DefaultQueryDefinitions]?: boolean | undefined } = {};
        
        for (const queryName in queries) {
            const queryValue = queries[queryName];

            if (queryValue === null) {
                result[queryName] = this.getQueryMatchState(queryName);
            } else {
                result[queryName] = this.registerCustomQuery(queryName, queryValue);
            }
        }

        for (const queryName in defaultQueryDefinitions) {
            result[queryName as keyof DefaultQueryDefinitions] = this.matchers.get(queryName)?.matches;
        }

        return result as { [K in keyof T | keyof DefaultQueryDefinitions]: boolean | undefined };
    }
}

export const mediaQueryManager: MediaQueryManager = new MediaQueryManager();

export default mediaQueryManager;
